@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

.color-violet-eggplant {
  color: #ae1fad; }

.margin-top-xs {
  margin-top: 8px; }

.margin-top-sm {
  margin-top: 16px; }

.margin-top-md {
  margin-top: 32px; }

.margin-top-lg {
  margin-top: 64px; }

.margin-bot-xs {
  margin-bottom: 4px; }

.margin-bot-sm {
  margin-bottom: 16px; }

.margin-bot-md {
  margin-bottom: 32px; }

.margin-bot-lg {
  margin-bottom: 64px; }

.bullet-icon {
  font-size: 12px; }

.tab-button-bar {
  user-select: none; }
  .tab-button-bar button {
    border-radius: 8px;
    border: solid 2px #dddddd;
    color: #000000; }
  .tab-button-bar button.active {
    border: solid 2px #088a00;
    color: #088a00; }
  .tab-button-bar button:not(:last-child) {
    margin-right: 37px; }
  .tab-button-bar .dropdown .toggle {
    color: #088a00;
    font-weight: bold; }
    .tab-button-bar .dropdown .toggle:after {
      border: none; }
  .tab-button-bar .dropdown .svg-inline--fa {
    margin-left: 16px; }
  .tab-button-bar .dropdown.open .svg-inline--fa {
    transform: rotate(180deg); }
  .tab-button-bar .dropdown .items > li span.active {
    color: #088a00;
    font-weight: bold; }
  @media (max-width: 767px) {
    .tab-button-bar .horizontal-menu {
      display: none; } }
  @media (min-width: 768px) {
    .tab-button-bar {
      overflow: auto;
      white-space: nowrap; }
      .tab-button-bar > .dropdown {
        display: none; } }

.page-wrapper {
  margin-top: 24px; }
  @media (min-width: 768px) {
    .page-wrapper {
      margin-top: 64px; } }

.icon-wrapper {
  text-align: right;
  pointer-events: none; }

@media (min-width: 768px) {
  .icon-wrapper.floating {
    right: 0;
    width: 100%;
    position: absolute;
    transform: translateY(-50%); } }

.footer-buttons {
  margin-top: 80px; }
  @media (max-width: 767px) {
    .footer-buttons {
      flex-direction: column-reverse; }
      .footer-buttons > div.column-two, .footer-buttons > div.column-two .button {
        width: 100%; }
      .footer-buttons > div:not(:first-child) {
        margin-bottom: 15px; } }
