header[role="banner"] {
  align-items: center;
  background-color: #ffffff;
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  margin-bottom: 62px;
  padding: 22px 0; }
  header[role="banner"] .wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    padding: 0 20px; }

.heading {
  font-family: Lato; }
