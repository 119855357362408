.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 30px;
  width: 50%;
  margin: auto; }

@media screen and (max-width: 768px) {
  .product-card {
    width: 100%; } }

.radio-selection {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 32px; }

.button-right {
  text-align: right;
  padding-top: 25px; }

.select-title {
  font-size: 24px !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important; }
