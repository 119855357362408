.groups-list header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 33px; }

.groups-list .group-card {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  padding: 35px 32px;
  position: relative;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.04); }
  .groups-list .group-card:hover {
    background-color: #eeeeee; }
  .groups-list .group-card:not(:last-child) {
    margin-bottom: 24px; }
  .groups-list .group-card.archived h2 {
    color: #6e6e6e; }
  .groups-list .group-card.archived hgroup,
  .groups-list .group-card.archived .pill {
    display: inline-block; }
  .groups-list .group-card.archived hgroup {
    margin-right: 50px; }
  .groups-list .group-card.archived .new-ribbon {
    background-color: #6e6e6e; }
    .groups-list .group-card.archived .new-ribbon:after {
      border-color: #4e4e4e transparent; }
    .groups-list .group-card.archived .new-ribbon:before {
      border-color: #6e6e6e #6e6e6e #6e6e6e transparent; }
  .groups-list .group-card.archived .pill {
    text-transform: uppercase; }
  .groups-list .group-card.archived .progress .count,
  .groups-list .group-card.archived .progress .percentage {
    color: #6e6e6e; }
  .groups-list .group-card.archived .progress-bar {
    background-color: #6e6e6e; }
  .groups-list .group-card h2 {
    color: #ae1fad;
    margin: 15px 0 5px 0;
    overflow-wrap: break-word;
    max-width: 90%; }
  .groups-list .group-card h3 {
    color: #6e6e6e;
    margin-bottom: 32px; }
  .groups-list .group-card .count {
    flex: 0 0 50%; }
  .groups-list .group-card .new-ribbon {
    background-color: #ae1fad;
    color: #ffffff;
    padding: 10px 10px 10px 0;
    position: absolute;
    top: 32px;
    right: -8px;
    text-align: center;
    width: 78px; }
    .groups-list .group-card .new-ribbon:after {
      border-color: #751275 transparent;
      border-width: 8px 8px 0 0;
      bottom: -8px;
      right: 0; }
    .groups-list .group-card .new-ribbon:after, .groups-list .group-card .new-ribbon:before {
      border-style: solid;
      content: "";
      display: block;
      position: absolute; }
    .groups-list .group-card .new-ribbon:before {
      border-color: #ae1fad #ae1fad #ae1fad transparent;
      border-width: 20px 10px;
      left: -10px;
      top: 0; }
    @media screen and (max-width: 767px) {
      .groups-list .group-card .new-ribbon {
        top: 5px; } }
  .groups-list .group-card .percentage {
    flex: 0 0 calc(50% - 20px);
    text-align: right; }
  .groups-list .group-card .progress {
    align-items: center;
    display: flex;
    justify-content: space-between; }
    .groups-list .group-card .progress .percentage {
      color: #ae1fad; }
  .groups-list .group-card .progress-bar {
    background-color: #ae1fad;
    position: absolute;
    left: 0;
    height: 100%; }
  .groups-list .group-card .progress-bar-container {
    background-color: #dddddd;
    border-radius: 0 0 8px 8px;
    content: "";
    height: 8px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }

.groups-list .group-heading {
  margin-top: 24px;
  margin-bottom: 24px; }

.groups-list .groups-sorting {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none; }
  .groups-list .groups-sorting > span:first-child {
    margin-right: 10px; }
  .groups-list .groups-sorting .sort-toggle {
    cursor: pointer;
    position: relative; }
    .groups-list .groups-sorting .sort-toggle.ascending:after, .groups-list .groups-sorting .sort-toggle.descending:after {
      content: "";
      height: 0;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -15px;
      width: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #000000; }
    .groups-list .groups-sorting .sort-toggle.descending:after {
      transform: rotate(180deg); }

.groups-list .groups-status-toggle span {
  cursor: pointer;
  text-decoration: underline; }

.groups-list .groups-status-toggle svg {
  margin-right: 10px; }

.groups-list .groups-container {
  background-color: #f9f9f9;
  border-radius: 7px; }
  .groups-list .groups-container > .padding {
    padding: 24px 32px 97px; }

.groups-list .no-groups {
  background-color: #f9f9f9;
  padding: 80px;
  text-align: center; }
  .groups-list .no-groups svg {
    color: #a1a1a1;
    display: block;
    margin: 0 auto 20px; }
