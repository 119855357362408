@media (max-width: 767px) {
  .wrap {
    max-width: 787px; } }

@media (min-width: 768px) {
  .wrap {
    max-width: 1011px; } }

@media (min-width: 992px) {
  .wrap {
    max-width: 1140px; } }

.horizontal-menu {
  position: relative;
  overflow: hidden; }
  .horizontal-menu .horizontal-menu-content {
    overflow-x: scroll;
    padding-right: 40px;
    white-space: nowrap;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .horizontal-menu .horizontal-menu-content::-webkit-scrollbar {
      width: 0;
      height: 0 !important; }
  .horizontal-menu .arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    display: none;
    pointer-events: none; }
  .horizontal-menu .arrows > button {
    display: inline-block;
    position: absolute;
    padding: 10px 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    pointer-events: auto;
    cursor: pointer;
    border-style: none;
    border-radius: 0px; }
    .horizontal-menu .arrows > button > svg {
      vertical-align: middle; }
    .horizontal-menu .arrows > button.arrow-left {
      /* fallback/image non-cover color */
      background-color: white;
      /* Firefox 3.6+ */
      background-image: -moz-linear-gradient(left, white 0%, transparent 100%);
      /* Safari 4+, Chrome 1+ */
      background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, white), color-stop(100%, transparent));
      /* Safari 5.1+, Chrome 10+ */
      background-image: -webkit-linear-gradient(left, white 0%, transparent 100%);
      /* Opera 11.10+ */
      background-image: -o-linear-gradient(left, white 0%, transparent 100%);
      /* IE10+ */
      background: -ms-linear-gradient(left, white 0%, transparent 100%);
      /* Standard */
      background: linear-gradient(to right, white 0%, transparent 100%);
      /* IE6-9 */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
      left: 0; }
    .horizontal-menu .arrows > button.arrow-right {
      right: 0;
      /* fallback/image non-cover color */
      background-color: transparent;
      /* Firefox 3.6+ */
      background-image: -moz-linear-gradient(left, transparent 0%, white 100%);
      /* Safari 4+, Chrome 1+ */
      background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, transparent), color-stop(100%, white));
      /* Safari 5.1+, Chrome 10+ */
      background-image: -webkit-linear-gradient(left, transparent 0%, white 100%);
      /* Opera 11.10+ */
      background-image: -o-linear-gradient(left, transparent 0%, white 100%);
      /* IE10+ */
      background: -ms-linear-gradient(left, transparent 0%, white 100%);
      /* Standard */
      background: linear-gradient(to right, transparent 0%, white 100%);
      /* IE6-9 */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ); }
    .horizontal-menu .arrows > button.disabled {
      display: none; }
  .horizontal-menu.overflow .arrows {
    display: inherit; }
