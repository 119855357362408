.welcome-info {
  width: 728px; }
  .welcome-info .logo-container {
    margin-bottom: 24px; }
  .welcome-info a {
    text-decoration: none;
    display: inline-block;
    color: #ae1fad; }
    .welcome-info a:hover {
      text-decoration: underline; }
  .welcome-info iframe {
    width: 100%;
    height: 386px;
    margin-top: 48px; }
    @media screen and (max-width: 400px) {
      .welcome-info iframe {
        height: 193px; } }
  .welcome-info p {
    color: #0b0c0c; }
  .welcome-info .logo-container {
    width: 250px; }
  .welcome-info .logo {
    width: 100%;
    height: 100%; }
  .welcome-info .gamma {
    font-size: 16px; }
  .welcome-info .bold {
    font-size: 18px; }
